import { SDK_VERSION, init as init$1 } from '@sentry/browser';

/**
 * Inits the React SDK
 */
function init(options) {
  options._metadata = options._metadata || {};
  options._metadata.sdk = options._metadata.sdk || {
    name: 'sentry.javascript.react',
    packages: [
      {
        name: 'npm:@sentry/react',
        version: SDK_VERSION,
      },
    ],
    version: SDK_VERSION,
  };
  init$1(options);
}

export { init };
